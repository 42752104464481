/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'linkdinReal': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<circle pid="0" cx="15" cy="15" r="15" _fill="#327AB0"/><g clip-path="url(#clip0_192_6764)" _fill="#fff"><path pid="1" d="M11.832 12.9H9.154V21h2.678v-8.1zM20.626 13.639c-.563-.619-1.308-.928-2.233-.928a3.25 3.25 0 00-.93.126 2.125 2.125 0 00-.705.355 3.469 3.469 0 00-.458.424c-.108.123-.213.269-.315.432v-1.151h-2.67l.008.392c.005.26.008 1.068.008 2.42 0 1.351-.005 3.115-.017 5.288h2.67V16.48c0-.278.03-.498.089-.661a1.74 1.74 0 01.515-.7c.23-.188.514-.283.856-.283.466 0 .807.164 1.026.487.22.324.33.773.33 1.346V21h2.67v-4.641c0-1.194-.281-2.102-.844-2.72zM10.51 9c-.45 0-.813.132-1.092.395A1.325 1.325 0 009 10.397c0 .398.137.73.407.997.27.266.628.4 1.072.4h.017c.455 0 .822-.134 1.1-.4.28-.267.416-.599.41-.997a1.365 1.365 0 00-.415-1.002C11.32 9.132 10.96 9 10.51 9z"/></g><defs><clipPath id="clip0_192_6764"><path pid="2" _fill="#fff" transform="translate(9 9)" d="M0 0h12.471v12H0z"/></clipPath></defs>'
  }
})
