/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'prevHug': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 6.705a.996.996 0 00-1.41 0L9 11.295a.996.996 0 000 1.41l4.59 4.59a.996.996 0 101.41-1.41l-3.88-3.89L15 8.115c.39-.39.38-1.03 0-1.41z" _fill="#09101D"/>'
  }
})
