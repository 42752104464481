<template>
  <div>
    <button
      @click="click"
      class="focus:outline-none"
      :type="type"
      :disabled="disabled"
      :class="`${defaultClasses} ${typeof(classes)=='string' ? classes : classes.join(' ')}`"
    >
        <slot name="icon"></slot>
        <span v-if="text" class="flex justify-center"><loader-css :color="loadColor" v-if="load && activateLoad"/><span v-if="!activateLoad" v-html="text"></span></span>
        <slot name="icon-append"></slot>
    </button>
  </div>
</template>

<script>
import LoaderCss from '../LoaderCss.vue'
export default {
  components: { LoaderCss },
    props: {
        primary: {
          type: Boolean,
          default: true
        },
        disabled: {
          type: Boolean,
          default: false
        },
        secondary: {
          type: Boolean,
          default: false
        },
        load: {
          type: Boolean,
          default: false
        },
        activateLoad: {
          type: Boolean,
          default: false
        },
        loadColor: {
            type: String,
            default: "#ffffff"
        },
        type: {
            type: String,
            default: "button"
        },
        text: {
            type: String,
            default: ""
        },
        classes: {
            type: [String, Array],
            default: ""
        }
    },
    data(){
      return {
        
      }
    },
    computed: {
      defaultClasses(){
        if(this.primary) return 'bg-primary rounded font-bold text-white p-4 hover:bg-carnation-600 hover:shadow-md'
        if(this.secondary) return 'bg-white rounded font-bold text-primary p-4 hover:bg-mercury-300 hover:text-black hover:shadow-md'
        return ''
      }
    },
    methods: {
      click(){
        this.$emit('click')
      }
    }
}
</script>