/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" _fill="none" _stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path pid="1" d="M250.26 166.05L256 288l5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 6z" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path pid="2" d="M256 367.91a20 20 0 1120-20 20 20 0 01-20 20z"/>'
  }
})
