/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mailing': {
    width: 38,
    height: 38,
    viewBox: '0 0 38 38',
    data: '<path pid="0" d="M24.142 32.453H1.187a1.188 1.188 0 010-2.374h22.955a1.188 1.188 0 010 2.374z" _fill="#EB5757"/><path pid="1" d="M20.976 37.994H4.353A4.357 4.357 0 010 33.641V4.354A4.357 4.357 0 014.353 0h16.623a4.357 4.357 0 014.353 4.354 1.188 1.188 0 01-2.374 0c0-1.091-.888-1.98-1.98-1.98H4.354c-1.09 0-1.978.889-1.978 1.98V33.64c0 1.09.888 1.979 1.978 1.979h16.623c1.09 0 1.979-.889 1.979-1.98v-5.874a1.188 1.188 0 012.374 0v5.875a4.357 4.357 0 01-4.353 4.353z" _fill="#EB5757"/><path pid="2" d="M35.224 23.746H18.6a2.773 2.773 0 01-2.77-2.77v-10.29a2.773 2.773 0 012.77-2.77h16.623a2.773 2.773 0 012.77 2.77v10.29a2.773 2.773 0 01-2.77 2.77zM18.6 10.29a.396.396 0 00-.395.396v10.29c0 .218.177.396.395.396h16.623a.396.396 0 00.395-.396v-10.29a.396.396 0 00-.395-.396H18.6z" _fill="#EB5757"/><path pid="3" d="M26.91 18.055c-.561 0-1.12-.13-1.63-.391l-8.81-4.596a1.188 1.188 0 011.099-2.105l8.802 4.59c.332.17.757.169 1.097-.004l8.793-4.586a1.187 1.187 0 111.098 2.105l-8.802 4.591a3.631 3.631 0 01-1.648.396z" _fill="#EB5757"/>'
  }
})
