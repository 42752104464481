/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.22 6.22a.75.75 0 011.06 0l10.5 10.5a.75.75 0 11-1.06 1.06L6.22 7.28a.75.75 0 010-1.06z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M17.78 6.22a.75.75 0 010 1.06l-10.5 10.5a.75.75 0 01-1.06-1.06l10.5-10.5a.75.75 0 011.06 0z" _fill="#000"/>'
  }
})
