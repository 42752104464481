/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<circle pid="0" cx="15" cy="15" r="14.5" _stroke="#EB5757"/><path pid="1" d="M17.5 8.506v1.08h-.95c-.183 0-.448.041-.678.218-.255.195-.393.497-.393.866v1.76h1.957l-.126 1.14H15.48v6.092h-1.697v-6.093H12.5v-1.141h1.282v-1.886c0-.424.11-.937.422-1.333.295-.374.82-.709 1.798-.709 0 0 0 0 0 0l1.498.006z" _fill="#EB5757" _stroke="#EB5757"/>'
  }
})
