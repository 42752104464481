/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'youtube': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<circle pid="0" cx="15" cy="15" r="14.5" _stroke="#EB5757"/><g clip-path="url(#clip0_611_767)"><path pid="1" d="M20.921 12.487c0-1.054-.776-1.903-1.734-1.903a85.239 85.239 0 00-3.976-.084h-.422c-1.35 0-2.676.023-3.975.084-.956 0-1.732.854-1.732 1.908A34.864 34.864 0 009 14.995c-.002.835.024 1.67.08 2.505 0 1.055.775 1.91 1.732 1.91 1.364.064 2.763.092 4.186.09 1.425.005 2.82-.025 4.186-.09.958 0 1.734-.855 1.734-1.91.056-.836.082-1.67.08-2.507.005-.835-.02-1.67-.077-2.506zm-7.07 4.807V12.69l3.399 2.301-3.399 2.304z" _fill="#EB5757"/></g><defs><clipPath id="clip0_611_767"><path pid="2" _fill="#fff" transform="translate(9 9)" d="M0 0h12v12H0z"/></clipPath></defs>'
  }
})
