/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'selectArrow': {
    width: 18,
    height: 19,
    viewBox: '0 0 18 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.47 5.715l1.06 1.06L9 13.306l-6.53-6.53 1.06-1.06L9 11.185l5.47-5.47z" _fill="#7F91A8"/>'
  }
})
