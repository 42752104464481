/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevronDown': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.97 5.22a.75.75 0 011.06 0L8 9.19l3.97-3.97a.75.75 0 011.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 010-1.06z" _fill="#fff"/>'
  }
})
