/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.856 3.617a8.113 8.113 0 119.015 13.493A8.113 8.113 0 015.856 3.617zm4.508.133a6.614 6.614 0 016.613 6.614M10.364 3.75a6.614 6.614 0 106.613 6.614" _fill="#fff" fill-opacity=".8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M15.327 15.327a.75.75 0 011.06 0l5.143 5.142a.75.75 0 11-1.06 1.06l-5.143-5.142a.75.75 0 010-1.06z" _fill="#fff" fill-opacity=".8"/>'
  }
})
