/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkSuccess': {
    width: 60,
    height: 61,
    viewBox: '0 0 60 61',
    data: '<g clip-path="url(#clip0_109_13154)"><circle pid="0" opacity=".2" cx="30" cy="30.442" r="30" _fill="#27AE60"/><circle pid="1" cx="30.001" cy="30.443" r="24.316" _fill="#27AE60"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M43.779 19.702c.525.46.578 1.258.119 1.783l-17.684 20.21a1.263 1.263 0 01-1.844.062l-7.58-7.58a1.263 1.263 0 011.787-1.786l6.624 6.625L41.998 19.82a1.263 1.263 0 011.782-.119z" _fill="#fff" _stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_109_13154"><path pid="3" _fill="#fff" transform="translate(0 .443)" d="M0 0h60v60H0z"/></clipPath></defs>'
  }
})
