/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebookReal': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<circle pid="0" cx="15" cy="15" r="15" _fill="#415A94"/><path pid="1" d="M17.758 14.07h-1.78v6.092h-2.696v-6.093H12v-2.141h1.282v-1.386c0-.99.504-2.542 2.72-2.542L18 8.008v2.078h-1.45c-.237 0-.571.111-.571.584v1.26h2.015l-.236 2.14z" _fill="#fff"/>'
  }
})
