/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkBlack': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.662 4.53c.26.227.286.622.058.882l-8.75 10a.625.625 0 01-.912.03l-3.75-3.75a.625.625 0 01.884-.884l3.278 3.278 8.31-9.498a.625.625 0 01.882-.058z" _fill="#000"/>'
  }
})
