/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'errorPanel': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.243 13.141L9.54 3.361a1.629 1.629 0 00-2.814 0l-5.69 9.756v.001l-.013.023a1.63 1.63 0 001.407 2.451h11.405a1.63 1.63 0 001.408-2.45zm-4.59-.19a.67.67 0 01-.948 0l-1.572-1.573L6.56 12.95a.67.67 0 11-.947-.948l1.572-1.573-1.517-1.518a.67.67 0 01.948-.948l1.517 1.517 1.516-1.517a.67.67 0 11.948.948L9.08 10.43l1.573 1.572a.671.671 0 010 .949z" _fill="#EB5757"/>'
  }
})
