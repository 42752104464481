/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'twitter': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<circle pid="0" cx="15" cy="15" r="14.5" _stroke="#EB5757"/><g clip-path="url(#clip0_611_751)"><path pid="1" d="M22.857 10.42a6.325 6.325 0 01-1.755.478 3.01 3.01 0 001.34-1.673 6.13 6.13 0 01-1.932.733A3.056 3.056 0 0018.287 9a3.037 3.037 0 00-3.044 3.029c0 .24.02.47.07.69a8.637 8.637 0 01-6.28-3.168 3.022 3.022 0 00.934 4.047 3.01 3.01 0 01-1.375-.372v.035a3.044 3.044 0 002.44 2.974c-.246.066-.52.1-.798.1-.194 0-.391-.011-.577-.051a3.071 3.071 0 002.847 2.108 6.152 6.152 0 01-3.776 1.293c-.25 0-.49-.012-.728-.04a8.608 8.608 0 004.672 1.358c5.606 0 8.67-4.616 8.67-8.617 0-.132-.005-.262-.011-.392a6.076 6.076 0 001.526-1.575z" _fill="#EB5757"/></g><defs><clipPath id="clip0_611_751"><path pid="2" _fill="#fff" transform="translate(8 9)" d="M0 0h14.857v12H0z"/></clipPath></defs>'
  }
})
