/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevronNext': {
    width: 65,
    height: 64,
    viewBox: '0 0 65 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.379 11.879a3 3 0 014.242 0l18 18a3 3 0 010 4.242l-18 18a3 3 0 01-4.242-4.242l15.878-15.88L21.38 16.122a3 3 0 010-4.242z" _fill="#F11C33"/>'
  }
})
