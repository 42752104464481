/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'loadAgain': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.25 2.25a9.723 9.723 0 017.053 3.019l.013.013L19.32 6.45a.75.75 0 01-1.138.977l-.977-1.137A8.221 8.221 0 0011.25 3.75h-.001A8.252 8.252 0 003 12a8.252 8.252 0 008.25 8.25m0-18C5.866 2.25 1.5 6.617 1.5 12s4.367 9.75 9.75 9.75a9.754 9.754 0 009.195-6.5.75.75 0 10-1.414-.5 8.254 8.254 0 01-7.781 5.5" _fill="#000"/><path pid="1" d="M21.75 4.566V9.75a.75.75 0 01-.75.75h-5.184a.75.75 0 01-.53-1.28l5.184-5.184a.75.75 0 011.28.53z" _fill="#000"/>'
  }
})
