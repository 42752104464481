/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<path pid="0" d="M14.826 13.252L8.96 3.495A1.113 1.113 0 008 2.951c-.398 0-.757.204-.961.544l-5.865 9.757a1.123 1.123 0 00.96 1.7h11.73a1.122 1.122 0 00.962-1.7zm-6.493-.3h-.667a.334.334 0 01-.333-.334v-.667c0-.184.15-.333.333-.333h.667c.184 0 .333.15.333.333v.667c0 .184-.15.333-.333.333zM8 10.283a.667.667 0 01-.667-.666v-2a.667.667 0 011.333 0v2a.667.667 0 01-.666.666z" _fill="#F2C94C"/>'
  }
})
