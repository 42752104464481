/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<g clip-path="url(#clip0_711_221)" _stroke="#C6D6EC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M13.125 8.568V4.857a3.125 3.125 0 10-6.25 0v3.71"/><path pid="1" d="M14.375 8.568h-8.75c-1.036 0-1.875.84-1.875 1.875v6.875c0 1.035.84 1.875 1.875 1.875h8.75c1.036 0 1.875-.84 1.875-1.875v-6.875c0-1.036-.84-1.875-1.875-1.875z"/></g><defs><clipPath id="clip0_711_221"><path pid="2" _fill="#fff" transform="translate(0 .443)" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
