/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevronBack': {
    width: 65,
    height: 64,
    viewBox: '0 0 65 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M43.621 11.879a3 3 0 010 4.242L27.743 32 43.62 47.879a3 3 0 01-4.242 4.242l-18-18a3 3 0 010-4.243l18-18a3 3 0 014.242 0z" _fill="#EB5757"/>'
  }
})
