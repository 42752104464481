/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heart': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<path pid="0" d="M11.029 3c-2.03 0-3.03 2-3.03 2S7 3 4.972 3C3.322 3 2.017 4.38 2 6.025c-.035 3.417 2.71 5.847 5.719 7.888a.5.5 0 00.562 0C11.29 11.872 14.034 9.442 14 6.025 13.983 4.38 12.677 3 11.029 3z" _stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
