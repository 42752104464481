/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'searchDrop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.125 7.875H1.875a1.125 1.125 0 010-2.25h20.25a1.125 1.125 0 010 2.25zM18.375 13.125H5.625a1.125 1.125 0 110-2.25h12.75a1.125 1.125 0 010 2.25zM13.875 18.375h-3.75a1.125 1.125 0 110-2.25h3.75a1.125 1.125 0 010 2.25z" _fill="#fff" fill-opacity=".8"/>'
  }
})
