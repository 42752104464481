/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloudDown': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<g clip-path="url(#clip0_843_3533)" _fill="#fff"><path pid="0" d="M17.152 7.492a4.666 4.666 0 00-1.735-.777.567.567 0 01-.429-.43c-.274-1.222-.864-2.27-1.732-3.063-1.008-.924-2.342-1.433-3.756-1.433-1.243 0-2.39.39-3.318 1.127a5.276 5.276 0 00-1.478 1.863.562.562 0 01-.398.312c-.94.19-1.79.593-2.438 1.164A3.955 3.955 0 00.5 9.3c0 1.213.51 2.32 1.434 3.12.883.763 2.072 1.183 3.347 1.183h3.657V8.21L7.647 9.5a.563.563 0 01-.815-.02.573.573 0 01.03-.787l2.24-2.239a.563.563 0 01.795 0l2.239 2.238c.22.221.233.584.013.806a.562.562 0 01-.797.002l-1.29-1.29v5.392h4.359c1.102 0 2.106-.31 2.828-.871.818-.636 1.25-1.547 1.25-2.63 0-1.053-.466-1.956-1.348-2.61z"/><path pid="1" d="M8.938 15.866a.563.563 0 101.124 0v-2.264H8.938v2.264z"/></g><defs><clipPath id="clip0_843_3533"><path pid="2" _fill="#fff" transform="translate(.5 .11)" d="M0 0h18v18H0z"/></clipPath></defs>'
  }
})
