/* eslint-disable */
require('./address')
require('./arrowForward')
require('./arrowPageNext')
require('./arrowPagePrev')
require('./avatar')
require('./buildProfile')
require('./checkBlack')
require('./checkSuccess')
require('./chevronBack')
require('./chevronDown')
require('./chevronNext')
require('./close')
require('./cloudDown')
require('./createAccount')
require('./dotsVid')
require('./emptyVideo')
require('./errorPanel')
require('./exploreSearch')
require('./eye')
require('./eyeOff')
require('./facebook')
require('./facebookReal')
require('./heart')
require('./info')
require('./instagram')
require('./langagePlanet')
require('./linkdinReal')
require('./loadAgain')
require('./lock')
require('./mailing')
require('./mailSentSuccess')
require('./meetAndDiscuss')
require('./nextHug')
require('./online')
require('./prevHug')
require('./receiveProposal')
require('./reload')
require('./search')
require('./searchDrop')
require('./selectArrow')
require('./shango')
require('./shangoSecond')
require('./twitter')
require('./twitterReal')
require('./upload')
require('./warning')
require('./youtube')
