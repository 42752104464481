/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'address': {
    width: 42,
    height: 34,
    viewBox: '0 0 42 34',
    data: '<path pid="0" d="M33.232 16.218l-4.913-1.478a10.694 10.694 0 001.495-5.068h0v-.005a8.817 8.817 0 00-17.633 0h0v.005a10.73 10.73 0 001.524 5.119l-4.296 1.434a.817.817 0 00-.467.398l-8 15.333h0a.816.816 0 00.722 1.194h0a.826.826 0 00.245-.037h0l10.396-3.251 8.4 3.23s0 0 0 0a.81.81 0 00.588 0s0 0 0 0l8.4-3.23 10.396 3.25a.816.816 0 00.98-1.132l-.041-.085h-.002l-7.293-15.248-.135.065.135-.065a.815.815 0 00-.501-.43zm-19.94 12.276l2.543-10.671a31.12 31.12 0 004.346 4.242v9.079l-6.89-2.65zm8.522 2.65v-9.079a31.12 31.12 0 004.347-4.242l2.543 10.671-6.89 2.65zm-.816-28.66a7.192 7.192 0 017.183 7.183c0 2.37-1.409 4.8-3.039 6.814-1.564 1.933-3.311 3.457-4.144 4.142-.834-.684-2.581-2.207-4.145-4.139-1.63-2.013-3.038-4.444-3.038-6.817a7.192 7.192 0 017.183-7.184zm-17.7 28.483l6.938-13.297 4.3-1.434-2.891 12.123-8.347 2.608zm35.487.027l-8.434-2.635-2.902-12.173 4.968 1.492 6.368 13.316z" _fill="#EB5757" _stroke="#EB5757" stroke-width=".3"/><path pid="1" d="M25.148 9.667v0a4.15 4.15 0 10-4.15 4.15h0a4.154 4.154 0 004.15-4.15zm-6.667 0a2.517 2.517 0 115.034 0 2.517 2.517 0 01-5.034 0z" _fill="#EB5757" _stroke="#EB5757" stroke-width=".3"/>'
  }
})
