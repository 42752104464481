<template>
  <div class="">
    <div @click.stop="showDropdown">
      <div :class="['flex items-center select-none rounded h-10 px-3 border w-full focus:outline-none', `${errors ? 'border-redio': 'border-mercury-700 focus-within:border-dark-blue-500'}`]">
       <input ref="input"  class="w-full h-full text-inkNormal-simple focus:outline-none select-none cursor-default" type="text" v-model="selectedData.label" readonly :placeholder="placeholder">
       <svgicon name="selectArrow" width="18" height="19"></svgicon>
      </div>
    </div>
    <transition v-if="!disable" name="fade">
      <div class="relative z-10" v-if="drop">
       <div :class="putOnTop" class="absolute mt-1 w-full rounded bg-gray-50 border border-mercury-700 max-h-52 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-white">
          <ul v-if="options.length != 0" class="flex flex-col divide-y divide-mercury-700">
            <li @mousedown="selectValue(option)" :class="option == selectedData ? 'bg-primary text-white' : '' "  class="flex items-center h-10 py-1 md:py-2 px-2 hover:bg-carnation-300 cursor-pointer" v-for="(option, i) of options" :key="i">
              {{ option.label }}
            </li>
          </ul>
       </div>
      </div>
    </transition>
    <span v-if="errors" class="flex space-x-1 text-xs md:text-sm mt-1 items-center">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 1.3335C4.32004 1.3335 1.33337 4.32016 1.33337 8.00016C1.33337 11.6802 4.32004 14.6668 8.00004 14.6668C11.68 14.6668 14.6667 11.6802 14.6667 8.00016C14.6667 4.32016 11.68 1.3335 8.00004 1.3335ZM8.00004 8.66683C7.63337 8.66683 7.33337 8.36683 7.33337 8.00016V5.3335C7.33337 4.96683 7.63337 4.66683 8.00004 4.66683C8.36671 4.66683 8.66671 4.96683 8.66671 5.3335V8.00016C8.66671 8.36683 8.36671 8.66683 8.00004 8.66683ZM7.33337 10.0002V11.3335H8.66671V10.0002H7.33337Z" fill="black" />
            </mask>
            <g mask="url(#mask0)">
                <rect width="16" height="16" fill="#DA1414" />
            </g>
        </svg>
        <span class="text-redio">{{ errors }}</span>
    </span>
  </div>
</template>

<script >
//@blur="close" @focus="open" tabindex="0"
export default {
  props: {
    errors:null,
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    putOnTop: {
      type: String,
      default: ''
    },
    objectReturn: {
      type: Boolean,
      default: false
    },
    initialValue: {
      default: ''
    },
    disable: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      drop: false,
      selectedData: {},
    }
  },
  methods: {
    outsideClickListener() {
      this.drop = false
    },
    showDropdown(){
      this.drop = !this.drop
    },
    selectValue(option){
      this.selectedData = option
      this.drop = false
      let value
      if (this.objectReturn) {
        this.$emit("input", this.selectedData);
      }
      else if(this.selectedData.value) {
        value = this.selectedData.value
        this.$emit("input", value);
      }
      else {
        value = this.selectedData.label
        this.$emit("input", value);
      }
      //console.log(value)
    }
  },
  async mounted() {
    if(this.initialValue) {
      let opt
      opt = this.options.find(option=>{
        return option == this.initialValue || option.label == this.initialValue || option.value == this.initialValue
      })
      if(opt) this.selectValue(opt)
      console.log('selectedData: ', this.selectedData)
    }
    document.body.addEventListener(
      'click',
      this.outsideClickListener.bind(this)
    )
  },
}
</script>

<style scoped>
.hiddScroll::-webkit-scrollbar {
  display: none;
}

.hiddScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
