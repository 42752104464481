/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dotsVid': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="5" cy="12" r="2" _fill="gray"/><circle pid="1" cx="12" cy="12" r="2" _fill="gray"/><circle pid="2" cx="19" cy="12" r="2" _fill="gray"/>'
  }
})
