/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrowForward': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.563 5.25l6.75 6.75-6.75 6.75M18.375 12H4.688" _stroke="#000" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
