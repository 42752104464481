/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'langagePlanet': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 0C4.486 0 0 4.486 0 10.001 0 15.514 4.486 20 10 20s10-4.486 10-9.999C20 4.486 15.514 0 10 0zm8.214 9.13h-2.458c-.306-2.85-1.632-5.245-2.773-6.83 2.825 1.099 4.902 3.707 5.23 6.83zm-9.084 0H6.2C6.561 6.334 8.078 4 9.13 2.678v6.454zm0 1.741v6.56c-1.853-2.064-2.859-4.263-2.992-6.56H9.13zm1.74 0h3.198c-.14 2.38-1.214 4.655-3.198 6.784V10.87zm0-1.74V2.42c1.048 1.259 2.751 3.712 3.134 6.71H10.87zM7.297 2.197C6.14 3.78 4.761 6.216 4.448 9.131H1.786c.34-3.226 2.544-5.903 5.511-6.934zm-5.51 8.674h2.61c.116 2.416 1.028 4.716 2.719 6.868-2.876-1.075-4.997-3.709-5.33-6.868zm11.396 6.75c1.632-2.118 2.51-4.379 2.626-6.75h2.405a8.279 8.279 0 01-5.03 6.75z" _fill="#fff"/>'
  }
})
