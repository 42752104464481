/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'instagram': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<g clip-path="url(#a)" _fill="#EB5757"><path pid="0" d="M17.67 9.6a2.68 2.68 0 012.67 2.68v5.44a2.68 2.68 0 01-2.67 2.68h-5.45a2.68 2.68 0 01-2.68-2.68v-5.44a2.68 2.68 0 012.68-2.68h5.45zm0-1.19h-5.45a3.87 3.87 0 00-3.87 3.87v5.44a3.87 3.87 0 003.87 3.87h5.45a3.87 3.87 0 003.86-3.87v-5.44a3.87 3.87 0 00-3.86-3.87z" _stroke="#EB5757" stroke-width=".1"/><path pid="1" d="M18.48 12.28a.82.82 0 110-1.64.82.82 0 010 1.64z"/><path pid="2" d="M13.44 13.5a2.13 2.13 0 113.01 3.01 2.13 2.13 0 01-3.01-3.02zm1.5-1.82a3.32 3.32 0 100 6.64 3.32 3.32 0 000-6.64z" _stroke="#EB5757" stroke-width=".1"/></g><circle pid="3" cx="15" cy="15" r="14.5" _stroke="#EB5757"/><defs><clipPath id="a"><path pid="4" _fill="#fff" transform="translate(7.47 7.53)" d="M0 0h15.53v14.94H0z"/></clipPath></defs>'
  }
})
